<template>
  <div>
    <steps ref="steps"></steps>
    <div class="border">
      <div class="flex Application">
        <div style="margin-right: 5px">申请状态:{{ statusTitle }}</div>
        <i class="el-icon-refresh icons" @click="RefresshClick"></i>
      </div>
      <div>
        <div class="flex inputOn">
          <div>打款验证</div>
          <div>
            <el-input
              v-model="input"
              placeholder="请输入银行打款金额进行验证"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="flex inputOn" style="margin-top: 50px">
        <el-button type="primary" @click="addClick">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getAccountStatusForZJ,
  bindCardConfirmForZJ,
} from "@/api/Accountopening";
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      input: "",
      bindCardTxsn: "",
      statusTitle: "未申请",
    };
  },
  mounted() {
    this.getStatusForZJ();
  },
  methods: {
    getStatusForZJ() {
      getAccountStatusForZJ().then((res) => {
        if (res.code == 0) {
          this.bindCardTxsn = res.data.bindCardTxsn;
          if (res.data.confirmCardStatus == 20) {
            this.statusTitle = "处理中";
          }
          if (res.data.confirmCardStatus == 30) {
            this.statusTitle = "确认绑卡成功";
            Vue.ls.set(types.active, 3);
            this.$emit("logoutHeader3", 3);
            this.$refs.steps.getactive();
          }
          if (res.data.confirmCardStatus == 40) {
            this.statusTitle = "确认绑卡失败";
          }
        }
      });
    },
    RefresshClick() {
      this.getStatusForZJ();
      this.$message({
        message: '刷新成功',
        type: "success",
      });
    },
    addClick() {
      if (this.input == "") {
        this.$message.error("请输入打款金额");
        return false;
      }
      let config = {
        amount: this.input,
        bindCardTxsn: this.bindCardTxsn,
      };
      bindCardConfirmForZJ(config).then((res) => {
        if (res.code == 0) {
           this.$message({
            message: res.message,
            type: "success",
          });
          this.getStatusForZJ()
        } else {
          this.$message.error(res.message);
        }
      });
      // Vue.ls.set(types.active, 3);
      // this.$emit("logoutHeader3", 3);
      // this.$refs.steps.getactive();
    },
  },
  components: {
    steps: () => import("./steps.vue"),
  },
};
</script>

<style lang="scss" scoped>
.border {
  border: 1px solid black;
  width: 100%;
  padding: 20px;
}
.Application {
  justify-content: right;
  margin-bottom: 5px;
}
.flex {
  display: flex;
  align-items: center;
}
.icons {
  cursor: pointer;
}
.inputOn {
  justify-content: center;
}
::v-deep .el-input__inner {
  width: 300px;
  margin-left: 10px;
}
</style>